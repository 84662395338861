import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import {
  AlarmFill,
  Award,
  BoxArrowInDown,
  Check2Circle,
  Facebook,
  Globe,
  Instagram,
  Linkedin,
  List,
  PenFill,
  People,
  PersonWorkspace,
  PiggyBank,
  Twitter,
} from 'react-bootstrap-icons';

import hero from '../assets/money.png';
import about from '../assets/about.png';
import why from '../assets/select.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  const toggleMobileMenu = () => {
    const menu = document.getElementById('mobile-menu');
    menu.classList.toggle('hidden');
  };

  const slides = [
    { title: "Bravin", description: "This is only my 3rd advance with you guys but you are all so friendly and accommodating I have recommended you to all I work with. I copied your URL and posted it to all my friends on Facebook and it can be Seen by over 100 people daily . Just a wonderful service with equally wonderful people. Thanks very much" },
    { title: "Lilian", description: "MkopoHaraka came through at the right time when my available options didn't work out." },
    { title: "Enock", description: "Thank you very much for help. I've never thought that taking out a loan can take so little time. But with your service it's no problem at all. If it wasn't for your help I wouldn't know what to do" },
    { title: "Kelvin", description: "I think and know that you and your team deserve more credit than you actually get. You have helped me out many, many times and each time the response time has been excellent. Even though I have a full time job, there is always some obstacle – money issue – that needs to be squashed and your company is there every time. Thanks again" },
    { title: "Patricia", description: "Since I met all your requirements, my payday loan was processed in less than an hour! This is such a great way of getting extra and much needed cash!" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='mx-auto max-w-7xl px-6 2xl:px-0'>
      <header className='flex items-center justify-between py-6'>
        <a href="#" className="font-bold text-4xl">
          Mkopo <span className='text-tahiti'>Haraka</span>
        </a>
        <nav className='hidden lg:block text-xl font-semibold'>
          <ul className="flex">
            <li className='mr-4'>
              <Link to="/" onClick={() => scrollToSection('home')} className="transition ease-in-out hover:text-tahiti">Home</Link>
            </li>
            <li className='mr-4'>
              <Link to="/" onClick={() => scrollToSection('about')} className="transition ease-in-out hover:text-tahiti">About Us</Link>
            </li>
            <li className='mr-4'>
              <Link to="/" onClick={() => scrollToSection('reviews')} className="transition ease-in-out hover:text-tahiti">Customer Reviews</Link>
            </li>
          </ul>
        </nav>
        <div className="hidden lg:block">
          <a href="#" className="mr-2 rounded-lg bg-tahiti px-4 py-2 font-semibold text-lg transition colors ease-in hover:bg-tahiti"
          onClick={() => { window.location.href = "/register"; }}> Apply for Loan</a>
        </div>
        <button className='lg:hidden' id='mobile-btn' onClick={toggleMobileMenu}>
          <List className='text-2xl'/>
        </button>
      </header>
      <div className="hidden bg-tahiti text-white text-xl rounded-lg mt-4 px-4 py-5" id="mobile-menu">
        <ul>
          <li>
            <Link to="/" onClick={() => scrollToSection('home')} className="transition ease-in-out hover:text-white">Home</Link>
          </li>
          <li>
            <Link to="/" onClick={() => scrollToSection('about')} className="transition ease-in-out hover:text-white">About Us</Link>
          </li>
          <li>
            <Link to="/" onClick={() => scrollToSection('reviews')} className="transition ease-in-out hover:text-white">Customer Reviews</Link>
          </li>
        </ul>
      </div>
      <main>
        <section className='mt-10 md:mt-20'>
          <div className="flex flex-col items-center lg:flex-row">
            <div className="w-full lg:w-1/2">
              <h1 className='text-3xl md:text-6xl'>
                Get first loan online within minutes. <br /> <span className='md:block'>Fast and Safe</span>
              </h1>
              <div className="mt-10">
                <button 
                  className='mr-2 transform rounded-lg bg-tahiti px-6 py-3 font-semibold text-lg text-white duration-100 ease-in hover:translate-y-2 hover:drop-shadow-lg'
                  onClick={() => { window.location.href = "/register"; }}
                >
                  Apply for Loan
                </button>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <img src={hero} alt="Hero" />
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4">
            <figure className='flex flex-col items-center rounded-b-3xl rounded-t-3xl px-20 py-4 drop-shadow-lg duration-100 ease-in hover:bg-raham'>
              <People className='text-6xl text-tahiti'/>
              <span>100000+</span>
              <small className='mt-2 text-center'>Happy Customers</small>
            </figure>
            <figure className='flex flex-col items-center rounded-b-3xl rounded-t-3xl px-20 py-4 drop-shadow-lg duration-100 ease-in hover:bg-raham'>
              <Globe className='text-6xl text-tahiti'/>
              <span>7</span>
              <small className='mt-2 text-center'>Offices</small>
            </figure>
            <figure className='flex flex-col items-center rounded-b-3xl rounded-t-3xl px-20 py-4 drop-shadow-lg duration-100 ease-in hover:bg-raham'>
              <Award className='text-6xl text-tahiti'/>
              <span>10</span>
              <small className='mt-2 text-center'>Awards</small>
            </figure>
            <figure className='flex flex-col items-center rounded-b-3xl rounded-t-3xl px-20 py-4 drop-shadow-lg duration-100 ease-in hover:bg-raham'>
              <PersonWorkspace className='text-6xl text-tahiti'/>
              <span>10+</span>
              <small className='mt-2 text-center'>Years of Service</small>
            </figure>
          </div>
        </section>
        <section id="about" className='mt-10 md:mt-20'>
          <div className='flex flex-col-reverse lg:flex-row'>
            <div className='w-full lg:w-1/2'>
              <img src={about} alt="About Us" />
            </div>
            <div className='w-full lg:w-1/2'>
              <h2 className='text-3xl md:text-4xl'>Who we Are</h2>
              <div className='mt-3 h-1 w-24 bg-raham'></div>
              <p className='mt-6 text-gray-500'>
                Mkopo Haraka is an initiative lender offering a wide range of loans online to cater to your financial needs. 
                You can get our online loans instantly anywhere. The quick, checking processing system verifies your 
                application as soon as possible, ensuring you get funds when you are in need.
              </p>
              <ul className='mt-6'>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> Our online process is simple, fast and allows you to request a loan online.</p>
                </li>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> All you need is a valid ID.</p>
                </li>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> The rest of the process happens online, with no need to visit a physical office.</p>
                </li>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> Our quick loans manage your immediate financial needs.</p>
                </li>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> This allows you to access higher amounts in the future at low interest rates.</p>
                </li>
                <li className='flex items-center'>
                  <Check2Circle className='mr-2 text-3xl text-tahiti'/>
                  <p> Remember to maintain money management habits and timely repayment to increase your score.</p>
                </li>
              </ul>
              <button className='mr-2 transform rounded-lg bg-tahiti px-6 py-3 text-white duration-100 ease-in hover:translate-y-2 hover:drop-shadow-lg'>
                Read More
              </button>
            </div>
          </div>
        </section>
        <section id="reviews" className='mt-10 md:mt-20'>
          <h2 className='text-3xl md:text-4xl'>Why Trust Us</h2>
          <div className='mt-3 h-1 w-24 bg-raham'></div>
          <p className='mt-6 text-gray-500'>
            No matter your financial needs, Mkopo Haraka offers competitive loan options with flexible repayment plans that make it easy to get the funds you need. So, don't wait.
          </p>
          <div className=' mt-10 flex flex-col items-start lg:flex-row'>
            <div className='w-full lg:w-1/2'>
              <ul>
                <li className='mb-4 flex'>
                  <AlarmFill className='mr-4 text-4xl text-tahiti'/>
                  <div>
                    <h3 className='text-2xl'>24/7 Support service</h3>
                    <p className='mt-2 text-gray-500'>
                      We provide fast service anytime, anywhere. Our loan decisions typically take 2 to 10 minutes. Once approved, the funds will be deposited into your account within a business day.
                    </p>
                  </div>
                </li>
                <li className='mb-4 flex'>
                  <PenFill className='mr-4 text-4xl text-tahiti'/>
                  <div>
                    <h3 className='text-2xl'>Detail Oriented</h3>
                    <p className='mt-2 text-gray-500'>
                      We guarantee confidentiality and data protection.
                    </p>
                  </div>
                </li>
                <li className='mb-4 flex'>
                  <BoxArrowInDown className='mr-4 text-4xl text-tahiti'/>
                  <div>
                    <h3 className='text-2xl'>Out of the Box Creativity</h3>
                    <p className='mt-2 text-gray-500'>
                      We provide you solutions at your difficult times.
                    </p>
                  </div>
                </li>
                <li className='mb-4 flex'>
                  <PiggyBank className='mr-4 text-4xl text-tahiti'/>
                  <div>
                    <h3 className='text-2xl'>Value for Money</h3>
                    <p className='mt-2 text-gray-500'>
                      We understand the value at which you can be assisted with your needs during unavoidable circumstances.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2">
              <img src={why} alt="Why Trust Us" />
            </div>
          </div>
        </section>
        <section>
          <Slider {...settings} className='border-l-8 border-tahiti px-6 py-3'>
            {slides.map((slide, index) => (
              <figure key={index} className="flex items-center">
                <h4 className='text-xl'>{slide.title}</h4>
                <p className='mt-5 italic'>{slide.description}</p>
              </figure>
            ))}
          </Slider>
        </section>
      </main>
      <footer className='mt-10 md:mt-10'>
        <div className='hidden justify-between lg:flex'>
          <div className='w-1/4'>
            <a href="#" className='font-bold'>
              Mkopo <span className='text-tahiti'>Haraka</span>
            </a>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam ipsa voluptatem amet tempora quia. Distinctio itaque a quasi ut corrupti? Nostrum laboriosam dolores inventore eveniet veritatis quasi. Reiciendis, facere voluptates?</p>
            <div className='mt-4 flex'>
              <Facebook className='text-2xl mr-4 text-tahiti'/>
              <Instagram className='text-2xl mr-4 text-tahiti'/>
              <Twitter className='text-2xl mr-4 text-tahiti'/>
              <Linkedin className='text-2xl mr-4 text-tahiti'/>
            </div>
          </div>
          <div className='w-1/4'>
            <h5 className='font-bold'>Quick Links</h5>
            <ul className='mt-1'>
              <li className='mb-1'>
                <Link to="/" className='transition-ease-in-out hover:text-tahiti'>Home</Link>
              </li>
              <li className='mb-1'>
                <Link to="/about" className='transition-ease-in-out hover:text-tahiti'>About</Link>
              </li>
              <li className='mb-1'>
                <Link to="/reviews" className='transition-ease-in-out hover:text-tahiti'>Customer Reviews</Link>
              </li>
              <li className='mb-1'>
                <Link to="/contact" className='transition-ease-in-out hover:text-tahiti'>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className='w-1/4'>
            <h5 className="font-bold"> Contact Us</h5>
            <ul className='mt-1'>
              <li className='mb-1'>
                <i className='bi bi-telephone-fill mr-2 text-tahiti'>0700000000</i>
              </li>
              <li className='mb-1'>
                <i className='bi bi-envelope-fill mr-2 text-tahiti'>mkopoharaka@gmail.com</i>
              </li>
              <li className='mb-1'>
                <i className='bi bi-geo-fill mr-2 text-tahiti'>Nairobi, Kenya</i>
              </li>
            </ul>
          </div>
          <div className='w-1/4'>
            <h5 className='font-bold'>Newsletter</h5>
            <p className='mt-1 text-tahiti'>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis soluta odit error aspernatur veniam repellendus iste quasi debitis deleniti excepturi, cum optio maxime inventore tempora dolores quo nisi facilis quibusdam?
            </p>
            <form action="" className='mt-2'>
              <input type="email" name='' id='' className='peer rounded-lg border-2 border-solid border-tahiti px-4 py-2 outline-tahiti' placeholder='Enter your email here' />
              <small className='invisible block text-sm text-tahiti peer-invalid:visible'>Please provide a valid email address</small>
              <button className='rounded-lg bg-tahiti px-4 py-2 text-white hover:bg-tahiti'
                onClick={() => { window.location.href = "/register"; }}
              >
                Apply for Loan
              </button>
            </form>
          </div>
        </div>
        <hr className='my-4 h-0.5 bg-tahiti' />
        <p className='mb-2 text-center'>© {new Date().getFullYear()}. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;