import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../assets/wa.jpg';
import loanImage from '../assets/bansa.jpg'; 
import TImage from '../assets/loooo.png'; 

function Home() {
    const [user, setUser] = useState({
        full_name: '',
        phone_number: '',
        ID_number: '',
        loanType: '',
        
    });

    const navigate = useNavigate();

    const data = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'full_name') {
          const nameRegex = /^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i;
          if (nameRegex.test(value) || value === '') {
            setUser({ ...user, [name]: value });
          }
        }
    
        if (name === 'phone_number') {
          const phoneRegex = /^\d*$/;
          if (phoneRegex.test(value) && value.length <= 10) {
            setUser({ ...user, [name]: value });
          }
        }
      };

    const getdata = async (e) => {
        e.preventDefault();
        const { full_name, phone_number, ID_number, loanType } = user;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                full_name,
                phone_number,
                ID_number,
                loanType,
            })
        }

        const res = await fetch('https://okoafirebase-default-rtdb.firebaseio.com/UserData.json', options);
        if (res.ok) {
            navigate('/apply');
        } else {
            console.error('Failed to submit data');
        }
    }

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-cover bg-center bg-opacity-80 backfilter-blur-sm" style={{backgroundImage: `url(${backgroundImage})`, position: 'relative'}}>
            <div className='hidden md:block md:w-1/3'>
                <h1 className='text-8xl font-semibold mb-4 text-black md:w-3/4 leading-snug'>Mkopo<span className='text-brandPrimary leading-snug'>Loan</span></h1>
                <p className='text-black text-3xl mb-8'>Let us help you sort your expenses <br /> top up a loan, rental loan, <br />car loan or emergencies</p>
            </div>
            <div className="mt-5  lg:w-1/2 h-50vh lg:h-60vh rounded overflow-hidden" style={{backgroundImage: `url(${loanImage})`, backgroundSize: 'cover', opacity: 0.8}}>
                <div className="lg:flex lg:flex-wrap">
                    <div className="px-4 md:px-0 lg:w-6/12">
                        <div className="text-center block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-black font-semibold">
                            <h1 className=" mt-1 pb-1 text-xl font-bold text-3xl">Get first loan online in minutes<br/> Fast and safe apply</h1>
                            <hr className=" bg-grey" />
                            <h3 className=" mt-1 pb-1 text-xl font-bold text-lg">
                                You can qualify for Ksh. 10,000 - 80,000 loan to MPESA
                            </h3>
                        </div>
                        <form method='POST' className="w-screen-md mx-auto p-4 shadow-lg m-6 bg-midnight rounded px-8" onSubmit={getdata}>
                            <div className="mb-2">
                                <label className="block text-black-700 text-sm font-bold mb-2" htmlFor="full_name">
                                    Full Name
                                </label>
                                <input
                                 type="text"
                                 id="full_name"
                                 name="full_name"
                                 value={user.full_name}
                                 className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                 required
                                 onChange={handleChange}
                                 pattern="^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$"
                                 title="Please enter a valid name"
                               />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tel">
                                    Mpesa Phone Number
                                </label>
                                <input
                                  type="tel"
                                  id="phone_number"
                                  name="phone_number"
                                  value={user.phone_number}
                                  className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  required
                                  onChange={handleChange}
                                  pattern="(07|01)[0-9]{8}"
                                  title="Phone number must start with 07 or 01 "
                                  maxLength="10"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
                                    National ID Number
                                </label>
                                <input
                                  type="text"
                                  id="ID_number"
                                  name="ID_number"
                                  value={user.ID_number}
                                  className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  required
                                  onChange={data}
                                  minLength="8"
                                  maxLength="8"
                                  inputMode="numeric"
                                />
                            
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="loanType">
                                    Loan Type
                                </label>
                                <select
                                    id="loanType"
                                    name="loanType"
                                    value={user.loanType}
                                    className="shadow border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                    onChange={data}
                                >
                                    <option value="">Select Loan Type</option>
                                    <option className='text-lg' value="carloan">Car Loan</option>
                                    <option className='text-lg' value="educationloan">Education Loan</option>
                                    <option className='text-lg' value="emergencyloan">Emergency Loan</option>
                                    <option className='text-lg' value="rentalloan">Rental Loan</option>
                                </select>
                            </div>
                            <h4 className="mb-8 mt-1 pb-1 text-md text-l">No CRB Check, No Guarantors. Disbursed to MPESA. No Paperwork</h4>
                            <div className="mb-12 pb-1 pt-1 text-center flex items-center justify-between">
                                <button
                                    type="submit"
                                    className="w-full bg-taham hover:bg-tahiti text-white font-semibold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                                >
                                    Apply for a loan
                                </button>
                            </div>
                            <div  className='block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-black font-semibold'>
                            <h4 className=" mt-1 pb-1 text-md text-l">By clicking on the "Apply" button, I confirm that I have read and accept the Terms & Conditions, and that the specified phone number is mine and the M-Pesa account is connected to it.</h4>
                            <hr className=' bg-gray-400'/>
                            <h4 className=" mt-1 pb-1 text-md text-l">Protected by reCAPTCHA and subject to the Google Privacy Policy and Terms of Service</h4>
                            </div>
                            
                        </form>
                    </div>
                    <div className="px-4 md:px-0 lg:w-6/12 flex justify-center">
                       <img src={TImage} alt="Loan" className="max-w-full h-auto md:h-60" />
                     </div>

                </div>
            </div>
        </div>
    );
}

export default Home;
