import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/Home";
import Register from "./components/Register";
import Verify from './components/Verify';
import LoanDetails from './components/LoanDetails';

function App() {
  return (
    <Router>
      <Routes>
       <Route path="/" element={<Home/>} />
       
       <Route path="/register" element={<Register />} />
        <Route path="/apply" element={<LoanDetails />} />
        <Route path="/verify" element={<Verify />} />
  
      </Routes>
    </Router>
  );
}

export default App;
