import React, { useState, useEffect } from 'react';

const Toaster = ({ data }) => {
    const [toastIndex, setToastIndex] = useState(0);
    const [currentToast, setCurrentToast] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setToastIndex((prevIndex) => (prevIndex + 1) % data.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [data]);

    useEffect(() => {
        setCurrentToast(data[toastIndex]);
    }, [toastIndex, data]);

    return (
        <div className="toast">
            {currentToast && (
                <div className="toast-content">
                    {currentToast}
                </div>
            )}
        </div>
    );
};

export default Toaster;
